import { Component } from '@angular/core';
import { CommonService } from './services/common.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
 
  constructor( private router:Router,
    private commonService:CommonService){
    commonService.serRandomTrackingId();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        localStorage.removeItem('ignoreLayout');
      });
  }
  
}
