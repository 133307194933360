 
        <div class="tags-input-wrapper" appClickOutSide (clickOutside)="onClickOutSide()">
            <div class="tags-input-box" (click)="onClick()">
                <ng-container *ngFor="let item of selectedItems"> 
                    <div class="each-tags"  >
                        <span>{{item.text}}</span>
                        <a (click)="onSelectClick(item,true)">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4L4 12" stroke="#646566" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 4L12 12" stroke="#646566" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a> 
                    </div>
                </ng-container>
            </div>
            <div class="tags-dropdown" *ngIf="showList">
                <div class="search-box">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#646566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.4998 17.5L13.9165 13.9167" stroke="#646566" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input type="text"  (keyup)="onKeyUp($event)"/>
                </div>
                <div class="checkbox-list-wrapper">
                 
                    <label class="checkbox-container" *ngIf="!isSelectAll && listData.length > 0" (click)="onSelectAll()">
                        <input type="checkbox" >
                        <span class="checkmark"  ></span>
                        <span class="checkbox-label-text">Select All</span>
                    </label>
                    <label class="checkbox-container" *ngIf="listData.length == 0" (click)="onSelectAll()"> 
                        <span class="checkbox-label-text">No data available</span>
                    </label>
                    <label class="checkbox-container" *ngIf="isSelectAll" (click)="onUnSelectAll()">
                        <input type="checkbox" checked>
                        <span class="checkmark" ></span>
                        <span class="checkbox-label-text">UnSelect All</span>
                    </label>
                    
                    <ng-container *ngFor="let item of listData">
                        <label class="checkbox-container" (click)="onSelectClick(item)">
                            <input type="checkbox"  [id]="'ch_' + item.text"  [checked]="item.selectd" >
                            <span class="checkmark"></span>
                            <span class="checkbox-label-text" >{{item.text}}</span>
                        </label>
                    </ng-container> 
                </div>
            </div>
        </div>
  